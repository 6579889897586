input.p-inputtext {
  padding: 0;

  &:focus {
    outline: 2px solid var(--bs--color-principal-negro) !important;

    & + .bs-btn-form {
      outline-width: 2px;
    }
  }

  outline: 1px solid var(--bs--color-grises-gris-80) !important;

  &.no-border {
    outline: none !important;
  }

  border: none;
  line-height: $form-size;
  @include border-radius(4px);
  font-size: 1.4rem;
  text-indent: 15px;
  margin: 0 0 6px 0;
  color: var(--bs--color-principal-negro);
  position: relative;
  width: 100%;
  font-family: $overPass_bold;

  @include placeholder {
    color: var(--bs--color-grises-gris-80);
    font-family: $overPass_regular;
  }

  .mix & {
    :not(.icon) {
      @include border-radius(4px 0 0 4px);
    }
  }

  .bs-color & {
    outline: 1px solid var(--bs--color-validacion-verde-50) !important;

    &.no-border {
      outline: none !important;
    }
  }

  /* .ng-invalid &, */
  .is-invalid &,
  /* &.ng-invalid, */
  &.is-invalid {
    outline: 2px solid var(--bs--color-secundario-rojo) !important;
    color: var(--bs--color-secundario-rojo) !important;
    font-family: $overPass_bold;

    @include placeholder {
      color: var(--bs--color-secundario-rojo);
    }

    &:focus {
      outline: 2px solid var(--bs--color-secundario-rojo) !important;
    }

    & + .bs-btn-form {
      outline-width: 2px;
    }

    &.no-border {
      outline: none !important;
    }
  }

  &[disabled] {
    & + .bs-btn-form {
      margin-left: 2px;
    }

    outline: 1px solid var(--bs--color-grises-gris-40) !important;

    &.no-border {
      outline: none !important;
    }

    .bs-color & {
      background-color: var(--bs--color-principal-blanco);
      outline: 1px solid var(--bs--color-validacion-verde-10) !important;

      &.no-border {
        outline: none !important;
      }

      @include placeholder {
        color: rgba($gris-50, 0.4) !important;
      }
    }

    cursor: not-allowed;
    color: rgba($gris-50, 0.8);
    opacity: 1;

    @include placeholder {
      color: rgba($gris-50, 0.8);
    }

    & + .caption,
    & + .caption.error {
      color: rgba($gris-50, 0.8);

      .bs-color & {
        opacity: 0.5;
        color: var(--bs--color-principal-blanco);
      }
    }
  }
}
