// para marcar zona
.border-rojo {
  outline: 1px solid var(--bs--color-secundario-rojo);

  &-dotted {
    outline: 1px dotted var(--bs--color-secundario-rojo);

    &.gris {
      outline-color: var(--bs--color-grises-gris-40);
    }
  }

  &.gris {
    outline-color: var(--bs--color-grises-gris-20);
  }
}

.bs-border-all {
  border: 2px solid var(--bs--color-grises-gris-10);

  &.th-3 {
    border-width: 3px;
  }

  &.th-4 {
    border-width: 4px;
  }

  &.gris-10 {
    border-color: var(--bs--color-grises-gris-10);
  }

  &.gris-15 {
    border-color: var(--bs--color-grises-gris-15);
  }

  &.gris-20 {
    border-color: var(--bs--color-grises-gris-20);
  }

  &.gris-40 {
    border-color: var(--bs--color-grises-gris-40);
  }

  &.gris-50 {
    border-color: var(--bs--color-grises-gris-50);
  }

  &.gris-80 {
    border-color: var(--bs--color-grises-gris-80);
  }

  &.no-border-tablet {
    @media #{$no-tablet} {
      border: none;
    }
  }

  &.no-border-mobile {
    @media #{$no-mobile} {
      border: none;
    }
  }
}

.bs-border {
  &-left {
    border-left: 2px solid var(--bs--color-grises-gris-20);
  }

  &-right {
    border-right: 2px solid var(--bs--color-grises-gris-20);
  }

  &-top {
    border-top: 2px solid var(--bs--color-grises-gris-20);
  }

  &-bottom {
    border-bottom: 2px solid var(--bs--color-grises-gris-20);
  }

  // color
  &-left,
  &-right,
  &-top,
  &-bottom {
    &.no-border-tablet {
      @media #{$no-tablet} {
        border: none;
      }
    }

    &.no-border-mobile {
      @media #{$no-mobile} {
        border: none;
      }
    }

    &.gris-10 {
      border-color: var(--bs--color-grises-gris-10);
    }

    &.gris-15 {
      border-color: var(--bs--color-grises-gris-15);
    }

    &.gris-20 {
      border-color: var(--bs--color-grises-gris-20);
    }

    &.gris-40 {
      border-color: var(--bs--color-grises-gris-40);
    }

    &.gris-50 {
      border-color: var(--bs--color-grises-gris-50);
    }

    &.gris-80 {
      border-color: var(--bs--color-grises-gris-80);
    }
  }

  &.no-border-tablet {
    @media #{$no-tablet} {
      border: none;
    }
  }

  &.no-border-mobile {
    @media #{$no-mobile} {
      border: none;
    }
  }

  &.th-3 {
    border-width: 3px;
  }

  &.th-4 {
    border-width: 4px;
  }
}

// para mostrar
.bs-block-color {
  @include flex();
  @include flex-position-child("center", "center");
  @include flex-position-vertical("center");
  @include border-radius(4px);
  margin: 6px 0;
  width: calc(100% - 12px);
  min-height: 50px;
  padding: 16px 6px;
  background: var(--bs--color-principal-verde);
}

.bs-bloque-group {


  .bs-bloque {
    margin-bottom: 0px;
    height: 100%;
  }

  @include grid(1, 20px, 20px);

  @media #{$desktop} {
    @include grid(2, 20px, 20px);
  }

  &.set-1-t {
    @media #{$tablet} {
      @include grid(1, 20px, 20px);
    }
  }

  &.set-2-t {

    @media #{$tablet} {
      @include grid(2, 20px, 20px);
    }
  }

  &.set-3-t {

    @media #{$tablet} {
      @include grid(3, 20px, 20px);
    }
  }

  &.set-4-t {

    @media #{$tablet} {
      @include grid(4, 20px, 20px);
    }
  }

  &.set-1-d {
    @media #{$desktop} {
      @include grid(1, 20px, 20px);
    }
  }

  &.set-2-d {

    @media #{$desktop} {
      @include grid(2, 20px, 20px);
    }
  }

  &.set-3-d {

    @media #{$desktop} {
      @include grid(3, 20px, 20px);
    }
  }

  &.set-4-d {

    @media #{$desktop} {
      @include grid(4, 20px, 20px);
    }
  }
}

.bs-bloque {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 50px;
  display: block;
  box-sizing: border-box;

  &[disabled] {
    opacity: 0.5;
  }

  &.centrar {
    display: grid;
    place-content: center;
  }

  .dummy {
    flex: 1;
    color: var(--bs--color-grises-gris-40);
    text-align: center;
  }

  >article {
    width: 100%;
    padding: 8px 16px;
    margin: auto;
    box-sizing: border-box;

    &.flex {
      @include flex();
      @include flex-position-child("left", "center");
      @include flex-position-vertical("center");

      .bs-selector,
      .bs-btn,
      .bs-chip,
      .bs-badges {
        flex: 1;
      }
    }

    &.padding-h-4 {
      padding: 4px 8px;
    }

    &.padding-h-8 {
      padding: 8px 8px;
    }

    &.padding-h-12 {
      padding: 12px 8px;
    }
  }

  &.color {
    &.verde {
      background-color: var(--bs--color-principal-verde);
    }

    &.azul {
      background-color: var(--bs--color-principal-azul);
    }

    &.gris-10 {
      background-color: var(--bs--color-grises-gris-10);
    }

    &.gris-15 {
      background-color: var(--bs--color-grises-gris-15);
    }

    &.azul-10 {
      background-color: var(--bs--color-secundario-azul-10);
    }

    &.amarillo-10 {
      background-color: var(--bs--color-validacion-amarillo-10);
    }
  }

  @for $i from 0 through 400 {
    $height: 0px+($i * 5);
    $name: 0+($i * 5);

    &.h-#{$name} {
      height: $height;
    }
  }

  &.scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    @include scrolling(6px,
      6px,
      6px,
      var(--bs--color-grises-gris-10),
      var(--bs--color-grises-gris-50));
  }

  @for $i from 0 through 200 {
    $height: 0px+($i * 5);
    $name: 0+($i * 5);

    &.min-h-#{$name} {
      min-height: $height;
    }

    &.m-h-#{$name} {
      max-height: $height;
    }
  }

  &.botonera {
    @include flex();
    @include flex-position-child("bw", "center");
    @include flex-position-vertical("center");

    .bs-btn {
      &+.bs-btn {
        margin-left: 8px;
      }
    }

    &:not(.extremos) {
      @include flex-direction("column", true);

      @media #{$tablet} {
        @include flex-position-child("bw", "center");
        @include flex-direction("row", false);
      }

      &.right {
        @media #{$tablet} {
          @include flex-position-child("right", "center");
          @include flex-direction("row", false);
        }
      }

      &.left {
        @media #{$tablet} {
          @include flex-position-child("left", "center");
          @include flex-direction("row", false);
        }
      }

      &.center {
        @media #{$tablet} {
          @include flex-position-child("center", "center");
          @include flex-direction("row", false);
        }
      }
    }

    &.extremos {

      .bs-btn-primary,
      .bs-btn-secondary,
      .bs-btn-back,
      .bs-btn-terciary {
        width: auto;
      }
    }
  }

  &.important {
    section {
      @include flex();
      @include flex-position-child("bw", "top");
      @include flex-position-vertical("top");

      figure {
        width: 16px;
        margin-right: 16px;
        margin-top: 5px;

        img {
          width: 16px;
          height: auto;
        }
      }

      p {
        flex: 1;
      }
    }
  }

  >img {
    width: 100%;
    height: auto;
  }

  &.background {
    @include border-radius(4px);
    padding: 16px;
    width: calc(100% - 32px);

    &.gris {
      background-color: var(--bs--color-grises-gris-10);
    }

    &.verde {
      background-color: var(--bs--color-principal-verde);
    }

    &.azul {
      background-color: var(--bs--color-principal-azul);
    }
  }

  &.resumen-perfil {
    article {
      @include grid(2, 4px, 4px);

      .col {
        @include flex();
        @include flex-position-child("left", "top");
        @include flex-position-vertical("top");

        .bs-vineta-emocion {
          margin: 0;
        }

        &.full {
          grid-column: span 2;
        }
      }
    }
  }
}

.bs-chip {
  margin: 0 8px 8px 0;
  background-color: var(--bs--color-principal-negro);
  color: var(--bs--color-principal-blanco);
  display: inline-block;
  padding: 4px 8px 3px;
  font-size: 1.4rem;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: top;
  @include border-radius(4px);
  box-sizing: border-box;
  font-family: $overPass_regular;

  &.no-margin {
    margin: 0;
  }

  &.mini {
    font-size: 1.2rem;
    margin: 4px 0;
    padding: 2px 8px;
    font-family: $overPass_regular;
  }

  &.gris-10 {
    background-color: var(--bs--color-grises-gris-10);
    color: var(--bs--color-grises-gris-80);
  }

  &.gris-20 {
    background-color: var(--bs--color-grises-gris-20);
    color: var(--bs--color-grises-gris-80);
  }

  &.gris-40 {
    background-color: var(--bs--color-grises-gris-40);
  }

  &.gris-50 {
    background-color: var(--bs--color-grises-gris-50);
  }

  &.gris-80 {
    background-color: var(--bs--color-grises-gris-80);
  }

  &.rojo {
    background-color: var(--bs--color-secundario-rojo);
  }

  &.verde {
    background-color: var(--bs--color-principal-verde);
  }

  &.azul {
    background-color: var(--bs--color-principal-azul);
  }

  &.azul-10 {
    background-color: var(--bs--color-secundario-azul-10);
    color: var(--bs--color-principal-azul);
  }

  &.amarillo {
    background-color: var(--bs--color-secundario-amarillo);
  }

  &.amarillo-10 {
    background-color: var(--bs--color-validacion-amarillo-10);
    color: var(--bs--color-secundario-amarillo);
  }

  &.verde-10 {
    background-color: var(--bs--color-validacion-verde-10);
    color: var(--bs--color-principal-verde);
  }

  &.verde-50 {
    background-color: var(--bs--color-validacion-verde-50);
  }

  &.verde-110 {
    background-color: var(--bs--color-principal-verde-hover);
  }

  &.full {
    width: 100%;
    text-align: center;

    margin: 0;
  }

  &.min-w {
    &.w-50 {
      min-width: 50px;
    }

    &.w-100 {
      min-width: 100px;
    }
  }
}

.no-mobile {
  @media #{$no-mobile} {
    display: none;
  }
}

.no-tablet {
  @media #{$no-tablet} {
    display: none;
  }
}
