$icon_arrow: url(#{$svgpath}arrow-button.svg);
$icon_add: url(#{$svgpath}icon-add.svg);
$icon_remove: url(#{$svgpath}icon-remove.svg);
$icon_arrow_secondary: url(#{$svgpath}arrow-button-secondary.svg);
$icon_arrow_back: url(#{$svgpath}arrow-button-back.svg);
$icon_arrow_back-white: url(#{$svgpath}arrow-button-back-white.svg);
$icon_arrow_next-white: url(#{$svgpath}arrow-button-next-white.svg);
$check_button_blue: url(#{$svgpath}check-button-blue.svg);
$check_button_blue_40: url(#{$svgpath}check-button-blue-40.svg);
$check_button_white: url(#{$svgpath}check-button-white.svg);
$icon_resumen: url(#{$svgpath}icon-resumen.svg);
$icon_delete: url(#{$svgpath}delete-button.svg);
$icon_delete-black: url(#{$svgpath}delete-button-black.svg);
$icon_delete_blue: url(#{$svgpath}delete-button-blue.svg);
$icon_arrow_up_green: url(#{$svgpath}arrow-button-up-green.svg);
$icon_arrow_down_green: url(#{$svgpath}arrow-button-down-green.svg);
$icon_arrow_up_blue: url(#{$svgpath}arrow-button-up-blue.svg);
$icon_arrow_down_red: url(#{$svgpath}arrow-button-down-red.svg);
$icon_calendar: url(#{$svgpath}icon-calendar.svg);
$icon_calendar_red: url(#{$svgpath}icon-calendar-red.svg);
$icon_search: url(#{$svgpath}icon-search.svg);
$icon_mic: url(#{$svgpath}icon-mic.svg);
$icon_mic_off: url(#{$svgpath}icon-mic-off.svg);
$icon_watch: url(#{$svgpath}icon-watch.svg);
$icon_watch_off: url(#{$svgpath}icon-watch-off.svg);
$icon-table-ok: url(#{$svgpath}icon-table-ok.svg);
$icon-table-nok: url(#{$svgpath}icon-table-nok.svg);
$icon-table-min: url(#{$svgpath}icon-table-min.svg);
$tooltip-file: url(#{$svgpath}tooltip-file.svg);
$icon-table-sort-up: url(#{$svgpath}icon-table-sort-up.svg);
$icon-table-sort-down: url(#{$svgpath}icon-table-sort-down.svg);
$icon-table-sort-mixto: url(#{$svgpath}icon-table-sort-mixto.svg);
$circle-sum: url(#{$svgpath}circle-sum.svg);
$icon-paginator-last: url(#{$svgpath}icon-paginator-last.svg);
$icon-paginator-next: url(#{$svgpath}icon-paginator-next.svg);
$icon-paginator-first: url(#{$svgpath}icon-paginator-first.svg);
$icon-paginator-prev: url(#{$svgpath}icon-paginator-prev.svg);
//icon-paginator-last

$icon-bullet: url(#{$svgpath}icon-bullet.svg);
$icon-menu: url(#{$svgpath}icon-ui-nav-menu.svg);
$accordeon-icon: url(#{$svgpath}accordeon-icon.svg);
$icon-menu-arrow: url(#{$svgpath}icon-menu-arrow.svg);

$icon-modulo-imagen-add: url(#{$svgpath}icon-modulo-imagen-add.svg);
$icon-modulo-imagen-del: url(#{$svgpath}icon-modulo-imagen-dell.svg);
$icon-modulo-archivo-detalle: url(#{$svgpath}icon-modulo-archivo-detalle.svg);
$icon-modulo-archivo-ver: url(#{$svgpath}icon-modulo-archivo-ver.svg);
$icon-modulo-archivo-quitar: url(#{$svgpath}icon-modulo-archivo-quitar.svg);

$hour-arrow-up: url(#{$svgpath}hour-arrow-up.svg);
$hour-arrow-down: url(#{$svgpath}hour-arrow-down.svg);

$accordion-arrow-up: url(#{$svgpath}icon-ui-nav-flecha-arriba.svg);
$accordion-arrow-down: url(#{$svgpath}icon-ui-nav-flecha-abajo.svg);




