@charset "UTF-8";
.bs-contenedor {
  max-width: 1200px !important;
}

.bs-optional {
  margin-left: 4px;
  color: var(--bs--color-grises-gris-50);
  font-size: 1.1rem;
  text-transform: uppercase;
}

.bs-accionables {
  width: auto;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  padding: 8px 24px 8px 4px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  border: 3px solid var(--bs--color-grises-gris-10);
  margin-bottom: 16px;
  background-color: var(--bs--color-principal-blanco);
  -webkit-box-shadow: 0px 8px 10px 0px rgba(100, 100, 100, 0.12);
  -moz-box-shadow: 0px 8px 10px 0px rgba(100, 100, 100, 0.12);
  -ms-box-shadow: 0px 8px 10px 0px rgba(100, 100, 100, 0.12);
  box-shadow: 0px 8px 10px 0px rgba(100, 100, 100, 0.12);
}
.bs-accionables:hover {
  background-color: var(--bs--color-grises-gris-10);
}
.bs-accionables.full {
  width: 100%;
}
.bs-accionables a {
  text-decoration: none;
  color: var(--bs--color-principal-negro);
  width: 100%;
  display: grid;
  grid-template-columns: 56px auto 12px;
}
.bs-accionables a span {
  height: 48px;
  display: grid;
  place-items: center start;
  line-height: 2rem;
  font-size: var(--bs--font-size-p);
}
.bs-accionables a svg {
  position: absolute;
  right: 10px;
  height: 16px;
  top: calc(50% - 8px);
  fill: var(--bs--color-grises-gris-40);
}

.p-datatable .p-datatable-wrapper table {
  min-width: 100% !important;
}

.p-inputgroup {
  outline: 1px solid var(--bs--color-grises-gris-40) !important;
  border-radius: 4px;
}
.p-inputgroup input.p-inputtext {
  outline: none !important;
  margin: 0;
}
.p-inputgroup input.p-inputtext[disabled] {
  outline: none !important;
}

.p-inputgroup-addon {
  color: var(--bs--color-principal-negro);
  padding-left: 10px;
  padding-right: 10px;
  background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
  border-right: 1px solid var(--bs--color-grises-gris-40) !important;
}

.input-spinner-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.input-spinner-container input {
  width: 100%;
  padding-right: 30px;
  /* Espacio para el spinner */
}

.input-spinner-container .bs-loader-html {
  position: absolute;
  right: 10px;
  /* Ajusta según el diseño */
  top: 25%;
  /*transform: translateY(-50%);*/
  /*width: 20px; !* Ajusta según el tamaño del spinner *!*/
  /*height: 20px;*/
}