.bs-selector {
  position: relative;
  min-height: 54px;
  @include border-radius(4px);
  border: 3px solid var(--bs--color-grises-gris-15);
  padding: 8px 16px;
  width: calc(100% - 32px);
  cursor: pointer;
  @include flex-inline();
  @include flex-position-child("bw", "center");
  @include flex-position-vertical("center");
  margin: 6px 6px 6px 0;
  text-decoration: none;
  color: var(--bs--color-principal-negro);

  &:hover {
    border: 3px solid rgba($azul, 0.5);
  }

  &.active {
    &.simple {
      background-color: var(--bs--color-principal-azul);
      border: 3px solid var(--bs--color-principal-azul);

      .label {
        color: var(--bs--color-principal-blanco);
      }

      &.icon {
        figure {
          img {
            filter: brightness(400%);
            -webkit-filter: brightness(400%);
          }
        }
      }
    }
  }

  &.icon {
    figure {
      width: 100%;
      margin: 0;

      img {
        height: 100%;
        width: auto;
      }
    }

    &.compacto {
      figure {
        height: 20px;
      }
    }
  }

  &.p-lr-2 {
    padding-left: 2px;
    padding-right: 2px;
  }

  &.p-lr-4 {
    padding-left: 4px;
    padding-right: 4px;
  }

  &.p-lr-8 {
    padding-left: 8px;
    padding-left: 8px;
  }

  &.simple {
    width: auto;
    min-width: 50px;
    max-width: 200px;



    span.label {
      line-height: 1;

    }

    &.center {
      span.label {
        text-align: center;
      }
    }

  }

  &.compacto {
    min-height: 32px;
    min-width: 16px;
    padding-left: 4px;
    padding-right: 2px;

    margin:0 2px 6px 0;
    @media #{$desktop} {
      margin-right: 8px;
      padding-left: 8px;
      padding-right: 8px;
    }

    &.small {
      margin: 0 0 4px 0;
      min-height: 20px;


    }
  }

  &.min-w {
    @for $i from 0 through 39 {
      $min-width: 10px+($i * 10);
      $name: 10+($i * 10);

      &.mw-#{$name} {
        min-width: $min-width;
      }
    }
  }

  @media #{$desktop} {
    width: auto;
    max-width: 600px;
    min-width: 200px;
  }

  &.w-100p {
    width: calc(100% - 32px);
  }

  &.min-0 {
    min-width: 0;
  }

  &.suspended {
    figure {
      opacity: 0.5;
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
    }

    span {

      &.label,
      &.preface,
      &.caption {
        color: var(--bs--color-grises-gris-50) !important;
      }

      &.arrow,
      &.delete {
        opacity: 0.5;
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
      }
    }
  }

  &[disabled] {
    border: 3px solid var(--bs--color-grises-gris-10);
    cursor: not-allowed;

    span {

      &.caption,
      &.label,
      &.preface {
        color: var(--bs--color-grises-gris-50);

        color: var(--bs--color-grises-gris-50);
      }

      &.arrow,
      &.delete {
        opacity: 0.5;
      }
    }
  }

  figure {
    margin-right: 16px;
    width: 30px;
    height: 30px;
    display: inline-block;

    img {
      width: 100%;
      height: auto;
    }
  }

  &.grande {
    figure {
      height: auto;
      max-height: 48px;
      width: 48px;
    }
  }

  &.superior {
    @include flex-position-child("bw", "top");

    span.arrow {
      margin-top: 20px;
    }
  }

  div.datos-informacion {
    width: 100%;
    flex: 0 0 100%;

    @include flex-inline();
    @include flex-position-child("bw", "center");
    @include flex-position-vertical("center");
    margin-top: 12px;
    padding-top: 8px;
    border-top: 1px solid var(--bs--color-grises-gris-20);

    .dato {
      span {
        display: block;
        color: var(--bs--color-grises-gris-50);
        font-size: 1.4rem;
        font-family: $overPass_regular;
        line-height: 1.3;
      }

      h4 {
        display: block;
        color: var(--bs--color-principal-azul);
        font-size: 1.8rem;
        font-family: $overPass_bold;
      }

      &:last-child {

        >span,
        >h4 {
          text-align: right;
        }
      }

      &.estado {
        flex: 1;
      }

      &.monto {
        flex: 1;
      }
    }
  }

  span {
    text-align: left;

    &.arrow {
      height: 20px;
      width: 20px;
      display: inline-block;
      background: $icon_arrow_secondary center center no-repeat;
      margin-left: 16px;
    }

    &.delete {
      height: 20px;
      width: 20px;
      display: inline-block;
      background: $icon_delete center center no-repeat;
      margin-left: 16px;
    }

    &.label {
      font-family: $overPass_bold;
      font-size: 1.5rem;
      color: var(--bs--color-principal-negro);
      flex: 1;

      line-height: normal;

      @media #{$desktop} {
        line-height: 2.2rem;
      }

      &+.caption {
        margin-left: 16px;
      }

      &.fix {
        font-size: 1.3rem;

        &+.fix {
          margin-left: 8px;
        }

        &+.arrow {
          flex: 1;
          margin-left: 8px;
        }
      }
    }

    &.bs-title {
      display: block;
      font-family: $overPass_bold;
      font-size: 1.5rem;
      color: var(--bs--color-principal-negro);
      line-height: normal;
    }

    &.bs-description {
      display: block;
      font-family: $overPass_regular;
      font-size: 1rem;
      color: var(--bs--color-grises-gris-80);
      line-height: normal;

      @media #{$tablet} {
        font-size: 1.1rem;
      }
    }

    &.preface {
      display: block;
      font-family: $overPass_regular;
      font-size: 1.3rem;
      color: var(--bs--color-principal-azul);
      line-height: normal;
    }

    &.bs-caption {
      display: block;
      font-family: $overPass_regular;
      font-size: 1.3rem;
      color: var(--bs--color-grises-gris-80);
      line-height: normal;
    }

    &.bs-caption {
      strong {
        color: var(--bs--color-principal-azul);
        font-family: $overPass_bold;
      }
    }
  }

  &.visited {
    border: 3px solid rgba($azul, 0.5);

    span {
      &.arrow {
        background-image: $check_button_blue;
      }
    }
  }
}

.bs-estados-generales {
  @include flex();

  @include flex-position-vertical("center");

  &.derecha {
    @include flex-position-child("right", "center");
  }

  &.izquierda {
    @include flex-position-child("left", "center");
  }

  &.centro {
    @include flex-position-child("center", "center");
  }

  h5 {
    font-size: 1.5rem;
    font-family: $overPass_bold;
    padding-right: 8px;

    @media #{$tablet} {
      font-size: 1.8rem;
    }
  }

  span {
    @include border-radius(50%);
    height: 12px;
    width: 12px;
    display: inline-block;
    background-color: var(--bs--color-principal-negro);

    @media #{$tablet} {
      height: 16px;
      width: 16px;
    }
  }

  &.verde {
    h5 {
      color: var(--bs--color-principal-verde);
    }

    span {
      background-color: var(--bs--color-principal-verde);
    }
  }

  &.azul {
    h5 {
      color: var(--bs--color-principal-azul);
    }

    span {
      background-color: var(--bs--color-principal-azul);
    }
  }

  &.rojo {
    h5 {
      color: var(--bs--color-principal-rojo);
    }

    span {
      background-color: var(--bs--color-principal-rojo);
    }
  }
}


.bs-selector-group {
  @include flex();
  @include flex-position-vertical("str");
  @include flex-position-child("left", "str");

  &.right {
    @include flex-position-child("right", "str");
  }

  &.center {
    @include flex-position-child("center", "str");
  }

  &.bw {
    @include flex-position-child("bw", "str");
  }
}
