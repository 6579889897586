.bs-bloque {
  &.set-filtros {
    @include grid(1, 16px, 4px);



    @for $i from 0 through 9 {

      $name: 1+$i;
      $name_t: $i;


      &.set-auto {
        @include flex();
        @include flex-position-child("right", "top");
        @include flex-position-vertical("top");

        label,
        button,
        a {
          min-width: 70px !important;
          width: 100%;
        }

        @media #{$tablet} {
          label {
            flex: 1;
          }

          a,
          button {
            width: auto;

          }
        }



        .bs-btn {
          margin-top: 20px;
          min-width: 0px;
        }

      }

      &.set-#{$name} {

        @media #{$tablet} {
          @include grid(#{$name_t}, 16px, 4px);
        }

        @media #{$desktop_b} {
          @include grid(#{$name}, 16px, 4px);
        }

        .bs-btn {
          margin-top: 20px;
          min-width: 0px;
        }

      }
    }
  }

}
