.bs-plan-setup {

  width: 100%;
  border: 4px solid var(--bs--color-grises-gris-10);
  box-sizing: border-box;
  @include flex();
  @include flex-position-child("center", "str");
  @include flex-position-vertical("str");
  margin-bottom: 16px;

  .top {
    background-color: var(--bs--color-secundario-azul-10);

    @include flex();
    @include flex-position-child("left", "center");
    @include flex-position-vertical("center");

    p-checkbox {
      margin: 0;

      .p-checkbox {
        margin-left: 0;

      }
      .p-checkbox-label {
        color: var(--bs--color-principal-azul) !important;
        font-size: 1.8rem !important;
        line-height: 1.2;
        font-family: $overPass_bold !important;
      }
    }
  }

  .bs-bloque {
    article {
      padding-top: 4px; padding-bottom: 4px;
    }
    &.interaccion {
      min-height: 120px;
      h4 {
        flex:0 0 100%;
      }
      .bs-selector {
        min-width: 10px;
        margin-left: 2px;margin-right: 2px;
      }
    }

    &.top {
      min-height: 60px;
      max-height: 30%;
    }

   // &:not(.interaccion):not(.top) {}
  }
}
.bs-plan-setup-total {
    background-color: var(--bs--color-secundario-azul-10);
  display: grid;
  place-content: center;
    width: 100%;
    border: 4px solid var(--bs--color-grises-gris-10);
    box-sizing: border-box;
    padding:20px 10px;

}
.bs-plan-setup-group {
  .col-12 {
    margin-bottom: 16px;
  }
  .bs-plan-setup {
    height: 100%;
    margin-bottom: 16px;
  }
}
