.bs-pestanas {
  width: 100%;
  display: block;
}
.p-tabview {
  .p-tabview-nav-content {
      .lineal & {
        border-bottom: 1px solid var(--bs--color-grises-gris-20);
      }
    }
  .p-tabview-panels {
    padding: 1rem 0px;
    border: 0 none;
  }

  .p-tabview-nav {
    background: none;
    // border: 1px solid #dee2e6;
    // border-width: 0 0 2px 0;
    flex-wrap: nowrap;
    width: 100%;
    overflow: auto hidden;
    padding-bottom: 2px;
    @include scrolling(2px, 2px, 6px, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    @media #{$tablet} {
      width: 100%;
      flex-wrap: wrap;
    }

    li {
      margin-right: 4px;
      @media #{$tablet} {
        margin-right: 8px;
      }
      @media #{$desktop} {
        margin-right: 16px;
      }
      &:not(.p-highlight):not(.p-disabled):hover {
        .p-tabview-nav-link {
          //background: #ffffff;
          border-color: #6c757d;
          color: var(--bs--color-principal-negro);
        }
      }

      .p-tabview-nav-link {
        border: solid rgba(0, 0, 0, 0);
        border-width: 0 0 3px 0;
        border-color: transparent transparent rgba(0, 0, 0, 0) transparent;
        background: none;
        color: #6c757d;
        padding: 1rem;
        //font-weight: 600;
        //border-top-right-radius: 3px;
        //border-top-left-radius: 3px;
        transition: box-shadow 0.2s;
        margin: 0 0 -2px 0;
        text-transform: uppercase;
        font-size: clamp(1.3rem, 1vw + 0.3rem, 1.5rem);
        font-family: $overPass_bold;
        &:not(.p-disabled):focus {
          outline: 0 none;
          outline-offset: 0;
          //box-shadow: 0 0 0 0.2rem #a6d5fa;
        }
        span {
          white-space: nowrap;
        }
      }
      &.p-highlight {
        .p-tabview-nav-link {
          //background: #ffffff;
          border-color: var(--bs--color-principal-azul);
          color: var(--bs--color-principal-azul);
             font-size: clamp(1.3rem, 1vw + 0.3rem, 1.5rem);
        }
      }
    }
  }
}
