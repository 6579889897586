figure.bs-iconos {
  @include figure-size(64px,64px);
  margin:0; padding:0;
  img {
    width: 100%; height: auto;
  }

  /*Sistema de tamaños, la caja */

  display: inline-block;
    &.center {
      margin: 0 auto;

      img {
        margin: 0 auto;
      }
    }
  &.block {
    display: block;
  }

  @for $i from 0 through 30 {
    $height: 5px + ($i * 5  );
    $name: 5 + ($i * 5 );

    &.w-#{$name} {
      @include figure-size($height,$height);

    }
  }

  $sizes: 8, 16, 24, 32, 48, 64, 72, 96, 144;
  @each $size in $sizes {
    &.w-#{$size} {
      @include figure-size(#{$size}px,#{$size}px);
    }
  }

}
