.bs-aplicacion-imagen-login {
  background-repeat: no-repeat;
  background-position: 100% center;
  background-size: auto 100%;
  width: 100%;
  position: relative;

  .bs-contenedor {
    @media #{$no-mobile} {
     //
    }

    .image-holder {
      figure {
        position: relative;

        @media #{$desktop_b} {
          position: absolute;
          top: 0%;
          right: calc(50% + 40px);
          height: 100%;
          width: calc(50% - 40px);
        }

        img {
          width: 100%;
          height: 150px;
          object-fit: cover;

          @media #{$desktop_b} {
            width: 100%;
            @include centrar();
            height: 100% !important;
            @include border-radius(0 2px 2px 0);
          }
        }
      }
    }
  }

  &.comercial {
    .image-holder {
      figure {
        img {
          @include border-radius(0 0 100px 0);

          @media #{$desktop_b} {
            @include border-radius(0 0 100px 0);


          }

        }
      }
    }
  }
}
