.bs-simple-data {
  width: 100%;
  display: block;
  margin-bottom: 16px;
&.no-margin {
    margin-bottom: 0px;
  }
  p,
  h3 {
    color: var(--bs--color-principal-negro);
    line-height: normal;
  }

  p.h3,
  h3 {
    font-size: 1.5rem;
    font-family: $overPass_bold;
  }

  p.h4,
  h4 {
    font-size: 1.4rem;
    font-family: $overPass_regular;
  }

  p:not(.h3):not(.h4) {
    font-size: 1.5rem;
    font-family: $overPass_regular;
  }

  &.azul {

    p:not(.h3):not(.h4) {
      color: var(--bs--color-principal-azul);

    }
  }
}

.bs-simple-data-tabla {
  border: 1px solid var(--bs--color-grises-gris-40);

  .modulo {
    width: calc(100% - 20px);
    min-height: 48px;
    @include flex();
    @include flex-position-child("left", "center");
    @include flex-position-vertical("center");
    padding: 10px;
    border-bottom: 1px solid var(--bs--color-grises-gris-40);

    &:last-child {
      border-bottom: none;
    }

    figure {
      margin-right: 10px;
      width: 24px;
      height: 24px;
    }

    p {
      flex: 1;
      line-height: 1.8rem;
    }
  }
}
