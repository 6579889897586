.bs-modulo-noticias {
  width: 100%;
  display: block;
  border: 1px solid var(--bs--color-grises-gris-20);
  @include border-radius(8px);

  figure {
    img {
      @include border-radius(8px 8px 0 0);
      max-height: 200px;
      width: 100%;
      object-fit: cover;
    }
  }

  figcaption {
    padding: 8px 16px 16px 16px;



    p.h3 {
      margin-bottom: 8px;
    }

    p:not(.h3) {
      margin-bottom: 16px;
    }
  }

  margin-bottom: 8px;
}
