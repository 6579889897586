// PATH
$fontpath: "~src/assets/fonts/";
$imgpath: "~src/assets/img/";
$svgpath: "~src/assets/img/svg/";

// MEDIA QUERIES
$no-mobile: "only screen and (max-width : 500px)";
$no-tablet: "only screen and (max-width : 810px)";
$mobile-345: "only screen and (min-width : 345px)";
$mobile-360: "only screen and (min-width : 360px)";
$mobile-375: "only screen and (min-width : 375px)";
$mobile-430: "only screen and (min-width : 430px)";

//
$tablet: "only screen and (min-width : 600px)";
$tablet_b: "only screen and (min-width : 768px)";

//
$desktop_t: "only screen and (min-width : 1280px)";
$desktop: "only screen and (min-width : 1000px)";
$desktop_b: "only screen and (min-width : 992px)";
//
$full: "only screen and (min-width : 1270px)";
$full-hd: "only screen and (min-width : 1340px)";
//
$big: "only screen and (min-width : 1560px)";

$container-size:1340px;
$big-container-size:clamp(1340px,100%,1600px);
$form-size:46px;
