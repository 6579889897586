$blanco: #ffffff !default;
$verde:#00953A !default;
$azul: #006FB9!default;
$negro:#595B5A !default;


$verde-hover:#037730 !default;
$azul-hover:#0C6098 !default;
$rojo-hover:#B91B24 !default;
// secundarios
$amarillo: #F7D500 !default;
$rojo:  #EF3742 !default;
$verde-50:  #80CA9C !default;
$azul-40:  #99C5E3 !default;
$azul-10:  #F0F8FE !default;
// escala de grises
$gris-5: #f9f9f9 !default;
$gris-10: #F4F4F4 !default;
$gris-15: #EFF1F4 !default;
$gris-20: #EAEAEA !default;
$gris-40: #E2E2E3 !default;
$gris-50: #ACADAC !default;
$gris-80: #8f8f8f !default;

// validacion

$verde-10: #E5F4EB !default;
$verde-50: #80CA9C !default;
$amarillo-10: #FEF9D9 !default;
$amarillo-50: #FBEA80 !default;
$rojo-10: #FFE7E9 !default;
$rojo-50: #F79BA0 !default;

$rojo-comercial: #f40034 !default;
$verde-comercial: #59a80f !default;
$azul-comercial: #005fff !default;
$amarillo-comercial: #f9b316!default;


