.bs-modulo-ficha {
  width: 100%;
  border: 2px solid var(--bs--color-grises-gris-15);
  @include border-radius(4px);
  background-color: var(--bs--color-principal-blanco);
  @include box-shadow(0px,
    12px,
    20px,
    0px,
    rgba(100, 100, 100, 0.05),
    false);

  margin-bottom: 8px;
  position: relative;
  box-sizing: border-box;
  @include flex();
  @include flex-position-child("bw", "str");
  @include flex-position-vertical("str");

  .avatar {
    position: absolute;
    height: 48px;
    width: 48px;
    top: 4px;
    left: 4px;
    display: block;

    svg {
      height: 48px;
      width: auto;
    }
  }

  a.boton-selector {
    text-transform: uppercase;
    font-family: $overPass_bold;
    font-size: 1.4rem;
    width: 100%;
    height: 36px;
    margin-bottom: 4px;
    @include flex();
    @include flex-position-child("bw", "center");
    @include flex-position-vertical("center");
    color: var(--bs--color-principal-negro);
    text-decoration: none;

    svg {
      fill: var(--bs--color-grises-gris-80);
      height: 16px;
      width: auto;
    }

    &:hover {
      opacity: 0.6;

      svg {
        fill: var(--bs--color-grises-gris-50);
      }
    }
  }

  &.extendida {
    @include flex();
    @include flex-position-child("bw", "str");
    @include flex-position-vertical("str");

    section {


      &.free {
        flex: 0 0 56px;
      }

      &.izq {
        flex: 1;

        @media #{$tablet} {
          flex: 0 0 45%;
        }
      }

      &.der {
        flex: 0 0 100%;
        background-color: var(--bs--color-grises-gris-5);
        position: relative;

        @media #{$tablet} {
          flex: 1;
        }

        a.bs-btn {

          box-sizing: border-box;
          width: 100%;

          @media #{$tablet} {
            width: 90%;
            @include centrar(50%, 95%);
          }
        }
      }
    }
  }

  section {
    box-sizing: border-box;
    padding: 8px;
    flex: 0 0 100%;

    .grid {
      @include flex();
      @include flex-position-child("bw", "str");
      @include flex-position-vertical("top");

      &+.grid {
        margin-top: 8px;
      }

      .bs-simple-data {
        flex: 1;
      }
    }

    &.superior {
      height: calc(100% - 48px);
      background-color: var(--bs--color-principal-blanco);
    }

    &.inferior {
      background-color: var(--bs--color-grises-gris-5);
      height: 48px;

      .botonera {
        min-height: 0px;
      }

      span,
      button,
      a {
        margin: 0 !important;
      }
    }


  }

  &.seguros {
    &.publicidad {
      padding-bottom: 16px;
      min-height: 370px;

      h2,
      p {
        width: 100%;
        padding: 0 8px;
        text-align: center !important;
        margin: 8px auto;
      }

      h2 {
        margin: 2px auto;
      }

      a,
      button {
        margin: 0 auto;
        max-width: 80%;
      }

      figure {
        width: 100%;

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          aspect-ratio: 2 / 1;


        }
      }
    }

    &.producto {
      &.simple {
        min-height: 370px;
        @include flex();
        @include flex-position-child("right", "str");
        @include flex-position-vertical("str");

        .superior {

          // height: calc(100% - 184px);
          padding-left: 50px;
          min-height: max-content;
          height: auto;
        }

        .inferior {
          flex: 0 0 100%;
          //   height: 184px
          height: auto;
          min-height: max-content;
        }
      }

      &.extendida,
      &.extendido {


        @media #{$tablet} {
          min-height: 420px;
          grid-column: span 2;
        }
      }
    }
  }

  &.siniestro {
    section.superior {
      padding-left: 64px;
    }
  }
}

.bs-modulo-fichas-group {
  @include grid(1, 16px, 16px);

  @media #{$tablet} {
    @include grid(2, 16px, 16px);
  }

  @media #{$desktop} {
    @include grid(3, 16px, 16px);
  }

  @media #{$full} {
    @include grid(4, 16px, 16px);
  }

  .bs-modulo-ficha {
    margin-bottom: 0px;
  }

  &.set-3 {

    @media #{$desktop} {
      @include grid(2, 16px, 16px);
    }

    @media #{$full} {
      @include grid(3, 16px, 16px);
    }
  }

  &.set-2 {
    @media #{$full} {
      @include grid(2, 16px, 16px);
    }
  }
}
