p-autocomplete {
  width: 100%;
  display: inline-block;
  position: relative;

  .p-autocomplete-multiple,
  .p-autocomplete {
    width: 100%;
    position: relative;
  }

  .p-autocomplete-multiple-container {
    margin-bottom: 6px;
  }

  .p-autocomplete-loader {
    @include centrar(100%, 35%);
    left: 92%;
    margin: 0;

    &:before {
      color: var(--bs--color-grises-gris-40);
    }
  }

  .p-autocomplete-clear-icon {
    @include centrar(100%, 55%);
    left: 96%;

    &:before {
      color: var(--bs--color-grises-gris-80);
    }

    /* .ng-invalid &, */
    .is-invalid & {
      &:before {
        color: var(--bs--color-secundario-rojo) !important;
      }
    }
  }

  .p-autocomplete-panel {
    background-color: var(--bs--color-principal-blanco);
    padding: 4px;
    border: 1px solid var(--bs--color-grises-gris-40);
    @include box-shadow(0px, -4px, 30px, 0px, rgba(0, 0, 0, 0.12), false);
    @include border-radius(2px 2px 8px 8px);

    ul {
      li {
        line-height: 3;
        padding: 0 2px 0 8px;

        span {
          font-family: $overPass_regular;
          font-size: var(--bs--font-size-p);
          color: var(--bs--color-principal-negro);
        }

        &:hover {
          background-color: var(--bs--color-grises-gris-5);
        }

        &:not(:last-child) {
          border-bottom: 1px solid var(--bs--color-grises-gris-20);
        }
      }
    }
  }

  /*   &.ng-invalid, */
  &.is-invalid {
    .p-autocomplete-loader,
    .p-autocomplete-clear-icon {
      &:before {
        color: var(--bs--color-secundario-rojo) !important;
      }
    }
  }
}
