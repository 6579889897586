.bs-forms {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  position: relative;
  .mix {
    position: relative;
    width: 100%;
    @include flex();
    @include flex-position-child("bw", "top");
    @include flex-position-vertical("top");
    input.p-inputtext {
      flex: 1;
      padding-right: 40px;
    }
    .btn {
      margin-bottom: 6px;
    }
    p-checkbox {
      flex: 1;
    }
  }
  p-checkbox {
    width: 100%;
  }
  &.bs-archivos {
    width: 100%;
     .bs-loader-container {

         @include centrar();
       }
    .modulos-archivos-compactos {
      margin-top: 12px;
      .modulo {
        @include border-radius(10px);
        position: relative;
        height: 48px;
        width: 56px;
        margin-right: 12px;
        margin-bottom: 12px;
        background-color: var(--bs--color-grises-gris-10);
        display: inline-block;

        p.caption {
          height: 16px;
          position: absolute;
          top:48px;
          left:0;
          width: 100%;
        }
        a.agregar {
          cursor: pointer;
          background: $icon-modulo-imagen-add center center no-repeat;
          height: 100%;
          width: 100%;
          display: block;
          @include centrar();
          &:hover {
            opacity: 0.5;
          }
        }
        a.detalle {
          height: 100%;
          width: 100%;
          display: block;
          cursor: pointer;
          @include centrar();
          background: $icon-modulo-archivo-detalle center center no-repeat;
          @include border-radius(10px);
          &:hover {
            background-color: var(--bs--color-grises-gris-50);
            &::after {
              filter: brightness(118%);
              -webkit-filter: brightness(118%);
            }
          }
          &::after {
            content: "";

            display: block;
            height: 23px;
            width: 23px;
            background: $icon-modulo-archivo-ver center center no-repeat;
            position: absolute;
            right: -8px;
            bottom: -8px;
          }
        }
        a.borrar {
          position: absolute;
          cursor: pointer;
          right: -8px;
          top: -8px;
          display: block;
          height: 23px;
          width: 23px;
          background: $icon-modulo-archivo-quitar center center no-repeat;
          &:hover {
            filter: brightness(90%);
            -webkit-filter: brightness(90%);
          }
        }
        &.agregado {
          margin-right: 20px;
          background-color: var(--bs--color-grises-gris-80);
        }
      }
    }
    .modulos-archivos {
      width: 100%;
      margin: 8px 0;
      @include grid(2, 2px, 2px);

      @media #{$tablet} {
          @include grid(3, 2px, 2px);
         @include flex();
      @include flex-position-child("left", "top");
      @include flex-position-vertical("top");
      }
      .modulo {
        position: relative;
        width: 100px;
        height: 80px;
        display: inline-block;
        @include border-radius(8px);
        //overflow: hidden;
        margin: 0 0px 36px 0;
        background-color: var(--bs--color-grises-gris-10);
        @media #{$tablet} {
          width: 100px;
          height: 90px;
          margin: 0 16px 36px 0;
        }
          p.caption {
          height: auto;
          position: absolute;
          top:100%;
          left:0;
          width: 100%;
          font-size: 1.1rem; line-height: 1;
          margin-top: 4px;
          color:var(--bs--color-grises-gris-80);

        }
        figure {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;

          img {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;

          }
        }
        &.icon {
          figure {


            img {
              max-width: 72px;
              height: auto;
              width: auto;
              @include centrar();
            }
          }
        }
        .borrar,
        .detalle,
        .agregar {
          position: absolute;
          display: block;
          cursor: pointer;
        }
        .detalle,
        .agregar {
          cursor: pointer;

          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
        .borrar {
          bottom: 0px;
          right: 0px;
          height: 30px;
          width: 30px;
          background: var(--bs--color-secundario-rojo) $icon-modulo-imagen-del
            center center no-repeat;
          @include border-radius(15px 15px 5px 15px);
          display: block;
          &:hover {
            background-color: var(--bs--color-secundario-rojo-hover);
          }
        }
        .detalle {
          &:hover {
            opacity: 0.3;
            background-color: var(--bs--color-principal-blanco);
          }
        }
        .agregar {
          background: $icon-modulo-imagen-add center center no-repeat;
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
