$overPass_regular :'overpassregular', sans-serif;
$overPass_bold:'overpassbold', sans-serif;
$overPass_light :'overpasslight', sans-serif;




@font-face {
    font-family:'overpassbold';
    src: url(#{$fontpath}overpass-bold-webfont.woff2) format('woff2'),
         url(#{$fontpath}overpass-bold-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'overpasslight';
    src: url(#{$fontpath}overpass-light-webfont.woff2) format('woff2'),
         url(#{$fontpath}overpass-light-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'overpassregular' ;
    src: url(#{$fontpath}overpass-regular-webfont.woff2) format('woff2'),
         url(#{$fontpath}overpass-regular-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

$overPass : $overPass_regular ;
$Arial: 'Arial', sans-serif;
